<template>
  <div class="pagination">
    <el-pagination background layout="total, prev, pager, next, jumper" :page-size="pageSize" :total="total" :current-page.sync="page" @current-change="changePage"></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 100
    },
    pageSize: {
        type: Number,
        default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      page: this.currentPage
    }
  },
  methods: {
    changePage (page) {
      this.$emit('changePage', page)
    }
  }
}
</script>

<style lang="less" scoped>
.pagination {
  text-align: center;
  margin: 20px;
}
</style>