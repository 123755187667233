<template>
  <div class="goods">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入商品名称"></el-input>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <el-button type="primary">
          <router-link to="/goods/details" @click.native="dispose" class="goto">新增商品</router-link>
        </el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="商品主图" width="100">
          <template slot-scope="imgs">
            <div class="table-image">
              <el-image :src="imgs.row.goods_img" :preview-src-list="[imgs.row.goods_img]"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="goods_price" label="商品价格" width="100"></el-table-column>
        <el-table-column prop="specification" label="规格" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="commission" label="佣金率" width="100"></el-table-column>
        <el-table-column prop="commission_price" label="赚佣金" width="100"></el-table-column>
        <el-table-column prop="is_enable" label="是否上架" width="100"></el-table-column>
        <el-table-column prop="created" label="添加日期"></el-table-column>
        <el-table-column prop="chain_name" label="所属机构" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="Code(scope.row)">二维码</el-button>
            <el-button size="mini" @click="handleEdit(scope.row)" :disabled="scope.row.disabled">编辑</el-button>
            <el-button size="mini" @click="handleAlter(scope.row)">{{scope.row.actionTemp === 1 ? '下' : '上'}}架</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="二维码" :visible.sync="dialogCode" width="360px" center :before-close="close">
      <el-image style="width: 310px; height: 310px;" :src="picUrl"></el-image>
      <el-button style="width: 310px" type="success" @click="down" plain>下载图片</el-button>
    </el-dialog>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import { shopGoodsList, changeStatus, delGoods, qrCode } from '../../../request/api'
import MyPagination from '../../../components/MyPagination.vue'
import { mapState } from 'vuex'

export default {
  inject: ['reload'],
  components: {
    MyPagination
  },
  data () {
    return {
      input: '',
      tableData: [],
      query_info: {
        chain_id: '',
				limit: 10,
				page: 1
      },
      total: 0,
      dialogCode: false,
      picUrl: 'https://',
      loading: false
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    // 获取商品列表
    async getGoodsList () {
      try {
        this.loading = true
        const res = await shopGoodsList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, this.input)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        res.data.data.goodsList.forEach(element => {
          let temp = {}
          temp.shop_goods_id = element.shop_goods_id
          temp.name = element.name
          temp.goods_price = element.goods_price
          temp.specification = element.specification
          temp.chain_name = element.chain_name
          temp.goods_img = element.goods_img[0]
          temp.is_enable = element.is_enable === 1 ? '已上架' : '未上架'
          temp.actionTemp = element.is_enable
          temp.created = element.created
          temp.commission = element.commission + '%'
          temp.commission_price = element.commission_price + '元'
          temp.disabled = element.chain_id === this.loginData.chain_id ? false : true
          this.tableData.push(temp)
        })
        this.total = res.data.data.totalNumber
      } finally {
        this.loading = false
      }
    },
    handleEdit (row) {
      if (row.is_enable === '已上架') {
        this.$message({
          type: 'warning',
          message: '该商品未下架'
        })
        return
      }
      this.$router.push(
        {
          path: '/goods/details',
          query: {
            shop_goods_id: row.shop_goods_id
          }
        }
      )
    },
    async handleAlter (row) {
      let postData = {
        shop_goods_id: row.shop_goods_id,
        is_enable: row.actionTemp === 0 ? 1 : 0
      }
      const res = await changeStatus(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.getGoodsList()
      this.$message({
        type: 'success',
        message: '修改成功！'
      })
      this.reload()
    },
    handleDelete (row) {
      if (row.is_enable === '已上架') {
        this.$message({
          type: 'warning',
          message: '该商品未下架'
        })
        return
      }
      this.$confirm('是否删除该商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let postData = {
          shop_goods_id: row.shop_goods_id
        }
        const res = await delGoods(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '删除成功！'
        })
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.tableData = []
      this.getGoodsList()
    },
    // 搜索按钮
    searchInp () {
      this.query_info.page = 1
      this.tableData = []
      this.getGoodsList()
    },
    dispose () {
      console.log('点击添加商品 之前的处理')
    },
    Code (row) {
      if (row.is_enable === '未上架') {
        this.$message({
          type: 'warning',
          message: '该商品未上架'
        })
        return
      }
      let postData = {
        shop_goods_id: row.shop_goods_id
      }
      const res = qrCode(postData)
      res.then(code => {
        if (code.status !== 200 || code.data.code !== '1000') this.$message.error(code.data.msg)
        this.picUrl = this.picUrl + code.data.data
        this.dialogCode = true
      })
    },
    // 关闭弹窗调用
    close () {
      this.picUrl = 'https://'
      this.dialogCode = false
    },
    // 下载图片
    down () {
      let image = new Image()
      // 解决跨域 canvas污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        // 绘制2D图
        let context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        let url = canvas.toDataURL('image/png')
        let a = document.createElement('a')
        let event = new MouseEvent('click')
        a.download = '商品图片'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = this.picUrl
    }
  },
  mounted () {
    this.getGoodsList()
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  }
}
</script>

<style lang="less" scoped>
.goods {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 20px;
      .goto {
        color: #fff;
      }
    }
  }
  .wrapper {
    margin: 20px 0;
    .table-image {
      width: 60px;
      height: 60px;
    }
  }
}
</style>